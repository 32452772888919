/** @jsx jsx */
// import React from 'react'
import { Box, jsx, Flex } from "theme-ui";
import { Link } from "gatsby";
import LayoutHome from "../layout/LayoutHome";

const Help = () => {
  const renderContentHeader = () => (
    <Box
      sx={{ textAlign: "center", backgroundColor: "#f7f7f7", padding: "40px" }}
    >
      <h1 sx={{ fontSize: "90px", fontWeight: "bolder" }}>
        <span sx={{ color: "#1da1f2" }}>H</span>
        <span sx={{ color: "#fe9717" }}>E</span>
        <span sx={{ color: "#a1df22" }}>L</span>
        <span sx={{ color: "#f67766" }}>P</span>
      </h1>
      <h3>
        <span sx={{ color: "#1da1f2" }}>H</span>eartfulness –
        <span sx={{ color: "#fe9717" }}>E</span>xperience
        <span sx={{ color: "#a1df22" }}>L</span>ife’s
        <span sx={{ color: "#f67766" }}>P</span>otential
      </h3>
    </Box>
  );

  return (
    <LayoutHome
      seoTitle="Help -Donations"
      contentHeader={renderContentHeader()}
    >
      <Flex
        sx={{
          width: "50%",
          maxWidth: "350px",
          margin: "40px auto",
          flexDirection: "column",
          border: "1px solid #999",
          textAlign: "center",
          padding: "20px 60px",
          borderRadius: "10px",
        }}
      >
        <p>Select your country</p>
        <Link
          sx={{
            border: "1px solid #1da1f2",
            margin: "10px",
            borderRadius: "10px",
            padding: "4px 8px",
          }}
          to="/help-usa"
        >
          USA
        </Link>
        <Link
          sx={{
            border: "1px solid #1da1f2",
            margin: "10px",
            borderRadius: "10px",
            padding: "4px 8px",
          }}
          to="/heartfulness-experience-lifes-potential-help"
        >
          INDIA
        </Link>
        <Link
          sx={{
            border: "1px solid #1da1f2",
            margin: "10px",
            borderRadius: "10px",
            padding: "4px 8px",
          }}
          to="/help-usa/?country=others"
        >
          OTHERS
        </Link>
      </Flex>
    </LayoutHome>
  );
};

export default Help;
